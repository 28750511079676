import React, { useEffect } from "react"
import Meta from "../utilities/seo"

const Ele = () => {
  useEffect(() => {
    window.location.href = "https://ke.myxeno.com"
  }, [])
  return (
    <>
      <Meta title="Investor Relations" />
    </>
  )
}

export default Ele
